@tailwind base;
@tailwind components;
@tailwind utilities;

#components-layout-demo-side .logo {
  height: 50px !important;
  margin: 8px !important;
  padding: 2px 2px 2px 12px;
  background: transparent !important;
}
#components-layout-demo-side .logo img {
  max-width: 100%;
  max-height: 100%;
  /* display: block;
  margin-left: auto;
  margin-right: auto; */
}
